// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3', // Change this to your desired primary color
    },
    secondary: {
      main: '#ff4081', // Change this to your desired secondary color
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif', // Change this to your desired font
  },
  // Add more custom styling properties as needed
});

export default theme;
